export const getUtmParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const utmParams = {};

    // Список UTM-параметров, которые мы хотим забрать
    const utmKeys = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];

    let hasUtmParams = false;

    utmKeys.forEach(key => {
        const value = urlParams.get(key);
        console.log(value);
        if (value) {
            utmParams[key] = value;
            hasUtmParams = true; // Установим флаг, если найден хотя бы один UTM-параметр
        }
    });

    // Проверяем, есть ли UTM-параметры и нет ли в localStorage уже сохранённых
    const existingUtmParamsString = localStorage.getItem('utmParams');

    if (hasUtmParams) {
        // Преобразуем существующий объект из localStorage, если он есть
        const existingUtmParams = existingUtmParamsString ? JSON.parse(existingUtmParamsString) : {};

        // Сохраняем параметры, если в localStorage нет фильтров или они пусты
        if (Object.keys(existingUtmParams).length === 0) {
            localStorage.setItem('utmParams', JSON.stringify(utmParams));
        }
    }
};