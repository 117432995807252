import CustomCheckBox from '../../../../../components/CustomCheckBoxComponent';
import DropdownForForm from '../../../../../components/DropDownForFormComponent';
import { PhotoList } from '../../RequiredCharacteristicsForm/PhotoList/PhotoList';
import styles from './VariationForm.module.css';
import { useState } from 'react';
import infoImg from '../../../../../assets/info-icon.svg';

export const VariationForm = ({ handleProductDataFieldChange, comboIndex, combination, productDataState, productData, setProductData, formErrors, isDigital }) => {
    const [videoToolTip, showVideoToolTip] = useState(false);
    const handleChange = (fieldName, value) => {
        const numericFields = [`combos.${comboIndex}.stock`, `combos.${comboIndex}.price`, `combos.${comboIndex}.total_price`, `combos.${comboIndex}.depth`, `combos.${comboIndex}.height`, `combos.${comboIndex}.width`, `combos.${comboIndex}.weight`];
        if (numericFields.includes(fieldName)) {
            if (isNaN(value)) {
                return;
            }
            value = Number(value);
        }
        if (fieldName === `combos.${comboIndex}.price`) {
            handleProductDataFieldChange(`combos.${comboIndex}.total_price`, value);
        }
        if (fieldName === `combos.${comboIndex}.video_url`) {
            handleProductDataFieldChange(`combos.${comboIndex}.video_url`, value);
        }

        handleProductDataFieldChange(fieldName, value);
    };

    const setPhotosList = (photos) => {
        handleProductDataFieldChange(`combos.${comboIndex}.photos`, photos);
    };

    const formatNumber = (num) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' '); // Добавление пробелов для разделения тысяч
    };

    const parseNumber = (str) => {


        // Проверка типа
        if (typeof str === 'number') {
            return str;
        }
        return Number(str.replace(/\s/g, '')); // Удаляем пробелы перед преобразованием в число
    };

    const handleInputChange = (name, value) => {

        // Сохраняем значение без пробелов для состояния
        const parsedValue = parseNumber(value);

        // Если значение корректное, обновляем состояние
        if (!isNaN(parsedValue)) {
            handleChange(name, parsedValue);
        }

    };

    if (!combination) return;

    const isWindows = () => {
        return navigator.userAgent.includes('Windows');
    };
    const isUserWindows = isWindows();



    return (
        <div className={styles.wrapper}>
            <div className={styles.fieldsColumn}>
                <div className={styles.formGroup}>
                    <span>Наименование</span>
                    <input
                        type="text"
                        name={`combos.${comboIndex}.name`}
                        value={combination.name}
                        placeholder="Введите название товара"
                        onChange={({ target }) => handleChange(target.name, target.value)}
                        required
                    />
                    {formErrors[`combo-${comboIndex}-name`] && <span className={styles.fieldError}>{formErrors[`combo-${comboIndex}-name`]}</span>}
                </div>
                <div className={styles.formGroup}>
                    <span>Код товара</span>
                    <input
                        type="text"
                        name={`combos.${comboIndex}.vendor_code`}
                        value={combination.vendor_code}
                        placeholder="Введите название товара"
                        onChange={({ target }) => handleChange(target.name, target.value)}
                    />
                    {formErrors[`combo-${comboIndex}-vendor_code`] && <span className={styles.fieldError}>{formErrors[`combo-${comboIndex}-vendor_code`]}</span>}
                </div>
                <div className={styles.formGroup}>
                    <span>Цена без скидки</span>
                    <input
                        type="text"
                        min="0"
                        name={`combos.${comboIndex}.price`}
                        placeholder="Введите начальную стоимость"
                        required
                        value={formatNumber(combination.price)}
                        onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                        onBlur={(e) => {
                            // Отформатировать при потере фокуса
                            const parsedValue = parseNumber(e.target.value);
                            if (!isNaN(parsedValue)) {
                                e.target.value = formatNumber(parsedValue);
                            }
                        }}
                        onFocus={(e) => {
                            // Удалить форматирование при фокусировке
                            e.target.value = combination.price.toString();
                        }}
                    />
                    {formErrors[`combo-${comboIndex}-price`] && <span className={styles.fieldError}>{formErrors[`combo-${comboIndex}-price`]}</span>}
                </div>
                <div className={styles.formGroup}>
                    <span>Цена со скидкой(если есть)</span>
                    <input
                        type="text"
                        name={`combos.${comboIndex}.total_price`}
                        placeholder="Введите итоговую стоимость"
                        required
                        onChange={(e) => { handleInputChange(e.target.name, (combination.price >= parseNumber(e.target.value) ? e.target.value : combination.price) ?? 0) }}
                        value={formatNumber(combination.total_price)}
                        onBlur={(e) => {
                            // Отформатировать при потере фокуса
                            const parsedValue = parseNumber(e.target.value);
                            if (!isNaN(parsedValue)) {
                                e.target.value = formatNumber(parsedValue);
                            }
                        }}
                        onFocus={(e) => {
                            // Удалить форматирование при фокусировке
                            e.target.value = combination.total_price.toString();
                        }}
                    />
                    {formErrors[`combo-${comboIndex}-total_price`] && <span className={styles.fieldError}>{formErrors[`combo-${comboIndex}-total_price`]}</span>}

                </div>
                <span className={styles.saleText}>Размер&nbsp;скидки&nbsp;-&nbsp;
                    <span>
                        {Number(combination.price) >= Number(combination.total_price) && combination.price !== '' && combination.total_price !== ''
                            ? Math.floor(((Number(combination.price) - Number(combination.total_price)) * 100) / Number(combination.price)) : "?"}
                    </span>%
                </span>
                <div style={{ display: 'flex', gap: 4, alignItems: 'center', marginTop: 10 }}>
                    <CustomCheckBox outId='alwaysInStock' checked={combination.always_in_stock} onChange={() => {
                        if (combination.stock === 0) {
                            handleChange(`combos.${comboIndex}.stock`, 1);
                        }
                        handleChange(`combos.${comboIndex}.always_in_stock`, !combination.always_in_stock);
                    }} />
                    <span><label htmlFor={'alwaysInStock'}>Всегда в наличии</label></span>
                </div>
            </div>
            <div className={styles.fieldsColumn}>
                <div className={styles.textArea}>
                    <span>Описание товара</span>
                    <textarea
                        name={`combos.${comboIndex}.description`}
                        placeholder="Введите описание товара"
                        required
                        value={combination.description}
                        onChange={({ target }) => handleChange(target.name, target.value)}
                    />
                    {formErrors[`combo-${comboIndex}-description`] && <span className={styles.fieldError}>{formErrors[`combo-${comboIndex}-description`]}</span>}
                </div>
                {!combination.always_in_stock && <div className={styles.formGroup} style={{ marginTop: '10px', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                        <span>Количество на складе</span>
                        <input
                            type="text"
                            min="0"
                            name={`combos.${comboIndex}.stock`}
                            value={combination.stock}
                            placeholder="Введите количество на складе" required
                            onChange={({ target }) => handleChange(target.name, target.value)}
                            style={{ maxWidth: 200 }}
                        />
                    </div>
                </div>}
            </div>
            <div className={styles.fieldsColumn}>
                <div className={styles.photosTitle} >Фотографии товара</div>
                {isUserWindows && <span style={{ color: "gray", fontSize: "17px" }}>Фото с расширением jpeg или png</span>}
                <PhotoList
                    photos={combination.photos}
                    comboIndex={comboIndex}
                    setProductData={setProductData}
                    productData={productData}
                    setPhotos={setPhotosList}
                />
                <div className={styles.formVideoInputCont}>
                    <div className={styles.formVideoInputNameCont}>
                        <span>Видео товара</span>
                        <div onMouseEnter={() => showVideoToolTip(true)} onMouseLeave={() => showVideoToolTip(false)} >
                            <img className={styles.infoImg} src={infoImg} alt="" />
                            {videoToolTip && (
                                <div className={styles.notificationBlock}>
                                    <p>Загрузите видео на Rutube или VK видео и вставьте ссылку</p>
                                </div>
                            )}
                        </div>
                    </div>
                    <input
                        type="text"
                        name={`combos.${comboIndex}.video_url`}
                        value={combination.video_url}
                        placeholder="Ссылка на видео с Rutube или VK видео"
                        onChange={({ target }) => {
                            handleChange(target.name, target.value);
                        }}

                    />
                    {formErrors[`combo-${comboIndex}-video_url`] && <span className={styles.fieldError}>{formErrors[`combo-${comboIndex}-video_url`]}</span>}
                </div>
            </div>
            {!isDigital && <div className={styles.requiredCharsWrapper}>
                <div className={styles.formGroupShort}>
                    <span>Длина, см</span>
                    <input
                        name={`combos.${comboIndex}.depth`}
                        value={combination.depth}
                        onChange={({ target }) => handleChange(target.name, target.value)}
                        required
                        type="number"
                        min="0"
                    />
                </div>
                <div className={styles.formGroupShort}>
                    <span>Высота, см</span>
                    <input
                        name={`combos.${comboIndex}.height`}
                        value={combination.height}
                        onChange={({ target }) => handleChange(target.name, target.value)}
                        required
                        type="number"
                        min="0"
                    />
                </div>
                <div className={styles.formGroupShort}>
                    <span>Ширина, см</span>
                    <input
                        name={`combos.${comboIndex}.width`}
                        value={combination.width}
                        onChange={({ target }) => handleChange(target.name, target.value)}
                        required
                        type="number"
                        min="0"
                    />
                </div>
                <div className={styles.formGroupShort}>
                    <span>Вес, гр</span>
                    <input
                        name={`combos.${comboIndex}.weight`}
                        value={combination.weight}
                        onChange={({ target }) => handleChange(target.name, target.value)}
                        placeholder="0"
                        required
                        type="number"
                        min="0"
                    />
                </div>
            </div>}
            <div className={styles.fieldsColumn} style={{ width: '100%' }}>
                <div className={styles.formGroup} style={{ maxWidth: '280px' }}>
                    <span>Статус товара</span>
                    <DropdownForForm
                        options={[{ id: '0', name: 'Опубликован' }, { id: '1', name: 'Черновик' }]}
                        initialChoice={productDataState ? '0' : '1'}
                        setChoice={(choiceID) => { handleProductDataFieldChange('state', choiceID === '0') }}
                        placeHolder="Опубликован/Черновик"
                    />
                </div>
            </div>
        </div>
    );
}